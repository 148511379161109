export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "dataRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Range"])},
        "thismonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month"])},
        "last30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 days"])},
        "last14days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 14 days"])},
        "last7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
        "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "customrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Range"])},
        "futureDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Next ", _interpolate(_list(0)), " days"])}
      },
      "th": {
        "dataRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่"])},
        "thismonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเดือนนี้"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเดือนที่แล้ว"])},
        "last30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูล 30 วันที่แล้ว"])},
        "last14days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูล 14 วันที่แล้ว"])},
        "last7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูล 7 วันที่แล้ว"])},
        "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเมื่อวานนี้"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลวันนี้"])},
        "customrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเอง"])},
        "futureDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ข้อมูล ", _interpolate(_list(0)), " วันข้างหน้า"])}
      },
      "zh": {
        "dataRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据范围"])},
        "thismonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个月"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month"])},
        "last30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 days"])},
        "last14days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 14 days"])},
        "last7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
        "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "customrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自选时间段"])},
        "futureDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Next ", _interpolate(_list(0)), " days"])}
      }
    }
  })
}
