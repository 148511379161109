<style>
#calendar{
    flex-wrap: nowrap;
}
#calendar > div {
    width: 100%;
}
</style>

<template>
  <div class="col-12 col-md-8 text-right mb-3" v-bind:class="{'col-lg-8 col-xl-8' : !['dashboard', 'dashboardAffiliate'].includes(pageName), 'col-lg-6 col-xl-6' : ['dashboard', 'dashboardAffiliate'].includes(pageName)}" v-if="pageName != 'dashboard' && pageName != 'dashboardAffiliate'">
    <div class="input-group" id="calendar">
      <span class="input-group-text" id="inputGroup-sizing-default"
        ><i class="fa fa-calendar text-primary me-1" aria-hidden="true"></i>
        {{$t('dataRange')}}</span>
      <v-date-picker
        v-model="criteria.range"
        is-range
        :columns="$screens({ default: 1, lg: 2 })"
        :masks="masks"
        is-required
        :available-dates="availableDate"
        v-if="renderComponent"
      >
        <template v-slot="{ inputValue, showPopover }">
          <div class="flex justify-center items-center">
            <input
              :value="inputValue.start + ' to ' + inputValue.end"
              @click="showPopover"
              class="form-control"
              aria-label="date2range input"
              aria-describedby="inputGroup-sizing-default"
              @focus="availableDateAction"
            />
          </div>
        </template>
      </v-date-picker>
    </div>
  </div>
  <div class="col-12 col-md-8 text-right mb-3" v-bind:class="{'col-lg-8 col-xl-8' : !['dashboard', 'dashboardAffiliate'].includes(pageName), 'col-lg-6 col-xl-6' : ['dashboard', 'dashboardAffiliate'].includes(pageName)}" v-else>
    <div class="input-group" id="calendar">
      <span class="input-group-text" id="inputGroup-sizing-default"
        ><i class="fa fa-calendar text-primary me-1" aria-hidden="true"></i>
        {{$t('dataRange')}}</span>
      <v-date-picker
        v-model="criteria.range"
        is-range
        :columns="$screens({ default: 1, lg: 2 })"
        :masks="masks"
        is-required
        :available-dates="availableDate"
        v-if="renderComponent"
      >
        <template v-slot="{ inputValue }">
          <div class="flex justify-center items-center">
            <input
              :value="inputValue.start + ' to ' + inputValue.end"
              class="form-control"
              aria-label="date2range input"
              aria-describedby="inputGroup-sizing-default"
              @focus="availableDateAction"
              disabled 
            />
          </div>
        </template>
      </v-date-picker>
    </div>
  </div>

  <div class="col-12 ms-auto text-right mb-3" v-bind:class="{'col-md-4 col-lg-4 col-xl-4' : !['dashboard', 'dashboardAffiliate'].includes(pageName), 'col-md-3 col-lg-3 col-xl-3' : ['dashboard', 'dashboardAffiliate'].includes(pageName)}">
    <button
      class="btn bg-gradient-info btn-block mx-auto dropdown-toggle"
      type="button"
      id="dropdownImport"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-selenium-name="select-date-value-dropdown"
      v-bind:data-selenium-value="criteria.dateRange"
    >
      {{convertIsDateFormat(displayDateRange)}}
    </button>
    <ul
      class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
      aria-labelledby="dropdownImport"
    >
      <li
        v-for="(rangeDate, idx) in displayDateformatList"
        v-bind:value="rangeDate.value"
        :key="rangeDate.value"
        data-selenium-name="date-value-dropdown-item"
        v-bind:data-selenium-value="idx+1"
      >
        <a
          class="dropdown-item"
          :class="{ active: rangeDate.value == criteria.dateRange }"
          href="javascript:;"
          @click="
            criteria.dateRange = rangeDate.value;
            rangeDate.value == 'customrange'
              ? ''
              : (criteria.range = rangeDate.range);
          "
          >
          {{rangeDate.label}}
          </a>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from "@/dayjs.js";
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";

export default {
  mixins: [mixin],
  name: "DatePicker",
  data: function () {
    return {
      Global,
      masks: {
        input: Global.dateFormat,
      },
      availableDate: {
        start: (this.dateType === "lastdateforinspection" || this.pageName === "cancellationReport") ? null : dayjs().subtract(7, 'month').format(Global.dateFormat),
        end: (this.dateType === "lastdateforinspection" || this.pageName === "cancellationReport") ? null : this.futureDate ? dayjs().add(this.futureDate, 'day').format(Global.dateFormat) : dayjs().format(Global.dateFormat)
      },
      rangeOfDate: [
        {
          label: "today",
          value: "today",
          range: {
            start: dayjs().format(Global.dateFormat),
            end: dayjs().format(Global.dateFormat),
          },
        },
        {
          label: "thismonth",
          value: "thismonth",
          range: {
            start: dayjs().startOf("month").format(Global.dateFormat),
            end: dayjs().format(Global.dateFormat),
          },
        },
        {
          label: dayjs().subtract(1, 'month').startOf("month").format('MMMM YY'),
          value: dayjs().subtract(1, 'month').startOf("month").format(Global.dateFormat),
          range: {
            start: dayjs().subtract(1, 'month').startOf("month").format(Global.dateFormat),
            end: dayjs().subtract(1, 'month').endOf("month").format(Global.dateFormat)
          }
        },
        {
          label: dayjs().subtract(2, 'month').startOf("month").format('MMMM YY'),
          value: dayjs().subtract(2, 'month').startOf("month").format(Global.dateFormat),
          range: {
            start: dayjs().subtract(2, 'month').startOf("month").format(Global.dateFormat),
            end: dayjs().subtract(2, 'month').endOf("month").format(Global.dateFormat),
          },
        },
        {
          label: dayjs().subtract(3, 'month').startOf("month").format('MMMM YY'),
          value: dayjs().subtract(3, 'month').startOf("month").format(Global.dateFormat),
          range: {
            start: dayjs().subtract(3, 'month').startOf("month").format(Global.dateFormat),
            end: dayjs().subtract(3, 'month').endOf("month").format(Global.dateFormat),
          },
        },
        {
          label: dayjs().subtract(4, 'month').startOf("month").format('MMMM YY'),
          value: dayjs().subtract(4, 'month').startOf("month").format(Global.dateFormat),
          range: {
            start: dayjs().subtract(4, 'month').startOf("month").format(Global.dateFormat),
            end: dayjs().subtract(4, 'month').endOf("month").format(Global.dateFormat),
          },
        },
        {
          label: dayjs().subtract(5, 'month').startOf("month").format('MMMM YY'),
          value: dayjs().subtract(5, 'month').startOf("month").format(Global.dateFormat),
          range: {
            start: dayjs().subtract(5, 'month').startOf("month").format(Global.dateFormat),
            end: dayjs().subtract(5, 'month').endOf("month").format(Global.dateFormat),
          },
        },
        {
          label: dayjs().subtract(6, 'month').startOf("month").format('MMMM YY'),
          value: dayjs().subtract(6, 'month').startOf("month").format(Global.dateFormat),
          range: {
            start: dayjs().subtract(6, 'month').startOf("month").format(Global.dateFormat),
            end: dayjs().subtract(6, 'month').endOf("month").format(Global.dateFormat),
          },
        },
        { label: "customRange", value: "customrange" },
      ],
      criteria: {
        range: {
          start: null,
          end: null,
        },
        dateRange: this.dateRange,
      },
      renderComponent: true,
    };
  },
  props: {
      pageName: String,
      start: String,
      end: String,
      dateRange: String,
      dateType: String,
      futureDate: Number
  },
  created() {
    if(!this.isNullOrEmpty(this.start) && !this.isNullOrEmpty(this.end)){
      this.criteria.range = { start: this.start,
                              end: this.end
                            };
    }
    else{
      this.criteria.range = this.rangeOfDate.find(
        (r) => r.value == this.criteria.dateRange
      ).range;
    }
    
    if(this.futureDate){
      this.rangeOfDate.push({
        label: "futureDate",
        value: "futureDate",
        valueReplaceList: [this.futureDate],
        range: {
          start: dayjs().format(Global.dateFormat),
          end: dayjs().add(this.futureDate, 'day').format(Global.dateFormat),
        },
      })
    }
  },
  methods: {
    triggerDateRange: function (_range) {
      const reverseRangeOfDate = JSON.parse(JSON.stringify(this.rangeOfDate));
      let dateRange = reverseRangeOfDate
        .reverse()
        .find(
          (r) =>
            r.range &&
            r.range.start == dayjs(_range.start).format(Global.dateFormat) &&
            r.range.end == dayjs(_range.end).format(Global.dateFormat)
        );
      if (dateRange) {
        this.criteria.dateRange = dateRange.value;
      } else {
        this.criteria.dateRange = "customrange";
      }
    },
    updateSelectedDate(selectedDate) {
      Global.searchConditions[this.pageName].dateFrom = selectedDate.range.start;
      Global.searchConditions[this.pageName].dateTo = selectedDate.range.end;
    },
    availableDateAction(){
      var isLastdateforinspection = (this.dateType === "lastdateforinspection" || this.pageName === "cancellationReport");
      this.availableDate.start = isLastdateforinspection ? null : dayjs().subtract(7, 'month').format(Global.dateFormat);
      this.availableDate.end = isLastdateforinspection ? dayjs().add(4, 'month').format(Global.dateFormat) : this.futureDate ? dayjs().add(this.futureDate, 'day').format(Global.dateFormat) : dayjs().format(Global.dateFormat);
    },
    convertIsDateFormat(data){
      return dayjs(data.value).isValid() ? this.$d(new Date(data.value), {month: 'short',year: '2-digit'}):data.value == 'futureDate' ? this.$t(data.value,data.valueReplaceList):this.$t(data.value);
    }
  },
  computed: {
    displayDateRange: function () {
      return this.rangeOfDate.find((r) => r.value == this.criteria.dateRange);
    },
    displayDateformatList:function () {
      this.rangeOfDate.map(r => {
        dayjs(r.value).isValid() ? r.label = this.$d(new Date(r.value), {month: 'short',year: '2-digit'}) : r.valueReplaceList ?  r.label = this.$t(r.value,r.valueReplaceList) : r.label = this.$t(r.value);
      });    
      if(this.pageName === 'dashboard' || this.pageName === 'dashboardAffiliate'){
        return this.rangeOfDate.filter(x => x.value !== 'customrange')
      }else{
        return this.rangeOfDate;
      }
    }
  },
  watch: {
    "criteria.range": function (value) {
      this.triggerDateRange(value);
      this.criteria.range.start = dayjs(this.criteria.range.start).format(Global.dateFormat);
      this.criteria.range.end = dayjs(this.criteria.range.end).format(Global.dateFormat);
      this.updateSelectedDate(this.criteria);
    },
    dateType: function(){
      if(this.pageName === "inspectionReport" && this.dateType !== 'lastdateforinspection' && new Date(Global.searchConditions[this.pageName].dateTo) > new Date()){
        this.criteria.range.start = dayjs().subtract(21, "days").format(Global.dateFormat);
        this.criteria.range.end = dayjs().format(Global.dateFormat);
        this.updateSelectedDate(this.criteria);
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      }
    }
  },
};
</script>

<i18n>
{
  "en":{
    "dataRange": "Data Range",
    "thismonth": "This month",
    "lastMonth": "Last month",
    "last30days": "Last 30 days",
    "last14days": "Last 14 days",
    "last7days": "Last 7 days",
    "yesterday": "Yesterday",
    "today": "Today",
    "customrange": "Custom Range",
    "futureDate": "Next {0} days"
  },
  "th":{
    "dataRange": "วันที่",
    "thismonth": "ข้อมูลเดือนนี้",
    "lastMonth": "ข้อมูลเดือนที่แล้ว",
    "last30days": "ข้อมูล 30 วันที่แล้ว",
    "last14days": "ข้อมูล 14 วันที่แล้ว",
    "last7days": "ข้อมูล 7 วันที่แล้ว",
    "yesterday": "ข้อมูลเมื่อวานนี้",
    "today": "ข้อมูลวันนี้",
    "customrange": "กำหนดเอง",
    "futureDate": "ข้อมูล {0} วันข้างหน้า"
  },
  "zh":{
    "dataRange": "数据范围",
    "thismonth": "这个月",
    "lastMonth": "Last month",
    "last30days": "Last 30 days",
    "last14days": "Last 14 days",
    "last7days": "Last 7 days",
    "yesterday": "Yesterday",
    "today": "今天",
    "customrange": "自选时间段",
    "futureDate": "Next {0} days"
  }
}
</i18n>